<template>
    <div class="col-12 col-lg-4 post-card-wrapper">
        <div class="image-wrapper" :class="this.$props.background">
            <div class="filtre-noir">
                <div class="title d-flex flex-column justify-content-center d-lg-block">
                    <h2><slot name="title"></slot></h2>
                </div>
            </div>
            
        </div>
        
        <div class="text-div">
            <div class="d-flex flex-column">
                
                <div class="presentation">
                    <p><slot name="presentation"></slot></p>
                </div>
                <div class="cta">
                    <a :href="this.$props.postUrl">Читать далее...</a>
                </div>
            </div>
        </div>
            
    </div>
</template>
<script>
export default {
    props:['background','postUrl']
}
</script>